<template>
  <div class="container">
    <header>Overview</header>
    <router-link to="/attribution">
      <div class="overviewItem">Attribution Report</div>
    </router-link>
    <router-link to="/">
      <div class="overviewItem">Next Project</div>
    </router-link>
    <router-link to="/">
      <div class="overviewItem">Next Project</div>
    </router-link>
  </div>
</template>

<script>
import Attribution from "./Attribution";

export default {
  name: "Overview",
  components:{
    Attribution
  }
}
</script>