<template>
  <div class="navigation">
    <router-link to="/">Overview</router-link>
  </div>
  <router-view/>
</template>

<style>

.navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  list-style: none;
  margin: 0;
  background: #0051ac;
}

.navigation a {
  text-decoration: none;
  display: block;
  padding: 1em;
  color: white;
}

.navigation a:hover {
  background: #002a79;
}

</style>
