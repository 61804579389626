import { createRouter, createWebHashHistory } from 'vue-router'
import Overview from "../components/Overview";
import Attribution from "../components/Attribution";

const routes = [
  {
    path: '/',
    name: 'Overview',
    component: Overview,
    meta: { title: 'Overview - Vegeta' }
  },
  {
    path: '/attribution',
    name: 'Attribution',
    component: Attribution,
    meta: { title: 'Attribution - Vegeta' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
