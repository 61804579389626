<template>
  <div class="container">
    <header>Attribution</header>
    <div class="aside">
      <label>Campaign-Number</label><br>
      <input type="text" v-model="campaignName"><br>
    </div>
    <div class="aside">
      <label>Start-Date</label>
      <Datepicker v-model="startDate"></Datepicker>
    </div>
    <div class="aside">
      <label>End-Date</label>
      <Datepicker v-model="endDate"></Datepicker>
    </div>
    <button>Report</button>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';

export default {
  name: "Attribution",
  components: {
    Datepicker
  },
  data(){
    return {
      campaignName: '',
      startDate: new Date(),
      endDate: new Date(),
      savedReports: [{name: 'Report1'}, {name: 'Report2'}, {name: 'Report3'}]
    }
  },
  methods: {
    createReport(){

    }
  }
}
</script>